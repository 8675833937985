<template>
  <div>
    <div v-if="!password.token" class="card card-error">
      Forkert URL. Tjek linket og prøv igen.
    </div>
    <template v-else-if="!passwordSent">
      <h1>
        Nulstil din adgangskode
      </h1>
      <p class="subheading">
        For at nulstille din adgangskode skal du oprette en ny.
      </p>
      <form @submit.prevent="processForm">
        <BaseInput
          name="password"
          type="password"
          v-model="password.password"
          label="Adgangskode*"
          placeholder="••••••••••••"
          :hasInput="!!v$.password.password.$model"
          :isValid="!v$.password.password.$invalid"
          :error="v$.password.password.$error ? v$.password.password.$errors[0].$message : ''"
          :error-additional="passwordErrorAdditional"
          @blur="v$.password.password.$touch()"
        />
        <BaseInput
          name="confirmPassword"
          type="password"
          v-model="password.confirmPassword"
          label="Gentag adgangskoden*"
          placeholder="••••••••••••"
          :hasInput="!!v$.password.confirmPassword.$model"
          :isValid="!v$.password.confirmPassword.$invalid"
          :error="v$.password.confirmPassword.$error ? v$.password.confirmPassword.$errors[0].$message : ''"
          @blur="v$.password.confirmPassword.$touch()"
        />
        <button type="submit" class="button-primary">
          Nulstil
        </button>
      </form>
    </template>
    <div v-else-if="loading" class="flex-grow flex items-center justify-center">
      Loader ...
      <Spinner class="ml-3" />
    </div>
    <template v-else-if="success">
      <div class="card">
        Din adgangskode er blevet ændret
      </div>
      <router-link :to="{ name: 'Login' }" class="button-primary">
        Log på
      </router-link>
    </template>
    <div v-else class="card card-error">
      <h6 class="">
        Der er gået noget galt med at gendanne din adgangskode
      </h6>
      <p v-if="error">
        {{ error }}
      </p>
      <p v-else>
        Prøv igen. Hvis du får samme besked, så kontakt vores kundeserviceteam på hackingalarm@skimsafe.dk
      </p>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput'
import Spinner from '@/components/Spinner'
import useVuelidate from '@vuelidate/core'
import { required, minLength, sameAs, helpers } from '@vuelidate/validators'
import ky from 'ky'

const pwdMinChars = 8
const customPasswordValidation = (value) => /[A-Z]/.test(value) && /[a-z]/.test(value) && /[^0-9a-zÀ-ž\s]/i.test(value)

export default {
  name: 'Password',
  components: {
    BaseInput,
    Spinner
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      password: {
        token: this.$route.query.token || '',
        password: '',
        confirmPassword: ''
      },
      passwordSent: false,
      loading: false,
      success: false,
      error: ''
    }
  },
  computed: {
    passwordErrorAdditional () {
      return this.v$.password.password.$error && this.v$.password.password.$errors[0].$validator === 'custom'
        ? 'Adgangskoden skal indeholde mindst et specialtegn, et stort bogstav og et lille bogstav.'
        : ''
    }
  },
  validations () {
    return {
      password: {
        password: {
          required: helpers.withMessage('Adgangskode er påkrævet', required),
          minLength: helpers.withMessage(`Adgangskoden skal være på mindst ${pwdMinChars} tegn`, minLength(pwdMinChars)),
          custom: helpers.withMessage('Adgangskoden skal være kompleks', customPasswordValidation)
        },
        confirmPassword: {
          required: helpers.withMessage('Gentag adgangskoden er påkrævet', required),
          sameAsPassword: helpers.withMessage('Adgangskoderne skal være ens', sameAs(this.password.password))
        }
      }
    }
  },
  methods: {
    async processForm () {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.passwordSent = true
        this.loading = true
        try {
          const passwordResponse = await ky.post('/api/reset_password', {
            json: {
              password: this.password.password,
              token: this.password.token
            }
          }).json()
          // console.log(passwordResponse)
          this.success = passwordResponse.success
          this.loading = false
          // if (!this.success) {
          //   // console.log(passwordResponse.error)
          //   this.error = passwordResponse.error || ''
          // }
        } catch (error) {
          // console.log(error)
          this.success = false
          this.loading = false
        }
      }
    }
  }
}
</script>
